// Description: The main Vuex store file. It contains the store object and the logic to handle the user authentication state. store/index.js
import { createStore } from 'vuex'
import { rtdb, auth, functions } from '../firebaseInit';
import { ref, onValue, update, off, onDisconnect } from 'firebase/database';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged, sendEmailVerification } from 'firebase/auth';
import { loadStripe } from '@stripe/stripe-js'
import { httpsCallable } from 'firebase/functions';
import i18n from '../languages/i18n';

const waitForRef = (reference, timeout = 5000) => {
    return new Promise((resolve, reject) => {
        const timeoutId = setTimeout(() => {
            cleanup();
            reject(new Error('Timeout waiting for user data'));
        }, timeout);

        const cleanup = () => {
            clearTimeout(timeoutId);
            off(reference);
        };

        onValue(reference, (snapshot) => {
            if (snapshot.exists()) {
                cleanup();
                resolve(snapshot.val());
            }
        }, (error) => {
            cleanup();
            reject(error);
        });
    });
};

const store = createStore({
    state: {
        user: null,
        tier: 'Free',
        language: 'de',
        planActive: false,
        authIsReady: false,
        conferenceId: null,
        liveTranslationActive: false,
        snackbar: false,
        snackbarMessage: '',
        snackbarTimeout: 5000,
        showQRModal: false,
        qrCodeLink: '',
        qrCodeText: '',
    },
    mutations: {
        async setUser(state, user) {
            state.user = user;
            if (user) {
                try {
                    const userRef = ref(rtdb, `users/${user.uid}`);
                    const userData = await waitForRef(userRef);

                    store.commit('setConferenceId', userData.conferenceId);
                    store.commit('setTier', userData.subscription);
                    store.commit('setIsAnnualSubscription', userData.isAnnualSubscription);
                    store.commit('setPlanActive', userData.planActive);
                    store.commit('setLanguage', userData.language || 'de');
                    store.commit('setPresence');
                } catch (error) {
                    console.error('Error fetching user data:', error);
                    // Handle the error appropriately, e.g., show a notification to the user
                }
            }
        },
        setTier(state, tier) {
            state.tier = tier;
        },
        setIsAnnualSubscription(state, isAnnualSubscription) {
            state.isAnnualSubscription = Boolean(isAnnualSubscription);
        },
        setPlanActive(state, value) {
            state.planActive = value;
        },
        setAuthIsReady(state, value) {
            state.authIsReady = value;
        },
        setConferenceId(state, conferenceId) {
            state.conferenceId = conferenceId;
        },
        setLiveTranslationActive(state, value) {
            state.liveTranslationActive = value;
        },
        setSnackbar(state, value) {
            state.snackbar = value;
        },
        setSnackbarMessage(state, message) {
            state.snackbarMessage = message;
        },
        setSnackbarTimeout(state, timeout) {
            state.snackbarTimeout = timeout;
        },
        setShowQRModal(state, value) {
            state.showQRModal = value;
        },
        setQRCodeLink(state, link) {
            state.qrCodeLink = link;
        },
        setQRCodeText(state, text) {
            state.qrCodeText = text;
        },
        setLanguage(state, language) {
            state.language = language
            i18n.global.locale = language;
        },
        setPresence(state) {
            const presenceRef = ref(rtdb, `conferences/${state.conferenceId}/presence`);
            if (window.innerWidth > 768) {
                update(presenceRef, { desktop: 'online' });
                onDisconnect(presenceRef).update({ desktop: 'offline' });
            } else {
                update(presenceRef, { mobile: 'online' });
                onDisconnect(presenceRef).update({ mobile: 'offline' });
            }
        }
    },
    actions: {
        async sendEmail({ dispatch }, { name, email, subject, message }) {
            try {
                const sendEmailFunction = httpsCallable(functions, 'sendEmail');
                const response = await sendEmailFunction({
                    name,
                    email,
                    subject,
                    message
                });

                if (response.data.success) {
                    dispatch('showSnackbar', {
                        message: i18n.global.t('email.successMessage'),
                        timeout: 3000
                    });
                    return { success: true };
                } else {
                    throw new Error('Failed to send email');
                }
            } catch (error) {
                console.error('Error sending email:', error);
                dispatch('showSnackbar', {
                    message: i18n.global.t('email.errorMessage'),
                    timeout: 5000
                });
                throw error;
            }
        },

        async sendPublicContact({ dispatch }, payload) {
            try {
                const sendContactFunction = httpsCallable(functions, 'sendPublicContactForm');
                const response = await sendContactFunction(payload);

                if (response.data.success) {
                    dispatch('showSnackbar', {
                        message: i18n.global.t('contact.successMessage'),
                        timeout: 3000
                    });
                    return { success: true };
                } else {
                    throw new Error('Failed to send contact form');
                }
            } catch (error) {
                console.error('Error sending contact form:', error);
                let errorMessage = i18n.global.t('contact.errorMessage');

                // Spezifische Fehlermeldungen
                if (error.code === 'resource-exhausted') {
                    errorMessage = i18n.global.t('contact.rateLimitError');
                }

                dispatch('showSnackbar', {
                    message: errorMessage,
                    timeout: 5000
                });
                throw error;
            }
        },
        showSnackbar({ commit }, { message, timeout }) {
            commit('setSnackbarTimeout', timeout || 5000);
            commit('setSnackbarMessage', message);
            commit('setSnackbar', true);
        },
        showQRModal({ commit }, { link, text }) {
            commit('setQRCodeLink', link);
            commit('setQRCodeText', text);
            commit('setShowQRModal', true);
        },
        async signUp({ commit }, { email, password }) {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            await commit('setUser', userCredential.user);
            await sendEmailVerification(userCredential.user);
        },
        async signIn({ commit }, { email, password }) {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            await commit('setUser', userCredential.user);
        },
        async signOut({ commit }) {
            await signOut(auth);
            await commit('setUser', null);
            commit('setConferenceId', null);
        },
        async checkoutPlan(_, { plan, isAnnual, cancelUrl }) {
            const stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);

            const stripeCheckout = httpsCallable(functions, 'stripeCheckout');
            await stripeCheckout({
                plan: plan,
                isAnnual: isAnnual,
                baseUrl: window.location.origin,
                cancelUrl: cancelUrl,
            })
                .then((result) => {
                    stripe.redirectToCheckout({ sessionId: result.data.sessionId });
                })
                .catch((error) => { console.error('Error:', error.message); });
        }
    },
    modules: {
    }
});


const unsub = onAuthStateChanged(auth, async (user) => {
    if (!user) {
        await store.commit('setUser', null);
        store.commit('setConferenceId', null);
    } else {
        await store.commit('setUser', user);
    }
    store.commit('setAuthIsReady', true);
    unsub();
});


export default store;