<template>
  <div class="h-full" :class="{ 'dark': popular }">
    <div class="relative flex flex-col h-full p-6 rounded-2xl shadow-md" :class="popular ? 'bg-slate-900' : 'bg-white'">
      <div v-if="popular" class="absolute top-0 right-0 mr-6 -mt-4">
        <div
          class="inline-flex items-center text-xs font-semibold py-1.5 px-3 bg-emerald-500 text-white rounded-full shadow-sm shadow-slate-950/5">
          {{ $t('mostPopular') }}
        </div>
      </div>
      <div class="mb-5">
        <div class="text-slate-900 dark:text-slate-200 font-semibold mb-1">{{ planName }}</div>
        <div class="inline-flex items-baseline mb-2">
          <span class="text-slate-900 dark:text-slate-200 font-bold text-3xl">€</span>
          <span class="text-slate-900 dark:text-slate-200 font-bold text-4xl"
            v-text="yearly ? price.yearly : price.monthly"></span>
          <span class="text-slate-500 font-medium" v-text="'/mo*'"></span>
        </div>
        <div class="text-sm text-slate-500 mb-5">{{ planDescription }}</div>
        <a class="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-blue-600 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-blue-700 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150 cursor-pointer"
          @click="this.$emit('subscribe', yearly)" v-if="showAboButton">
          <div v-if="!isLoading">
            {{ buttonText }}
          </div>
          <div class="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-white-500 mx-auto" v-else>
          </div>
        </a>
        <a class="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-blue-600 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-blue-700 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150 cursor-pointer"
          @click="this.switchPlan()" v-if="showSwitchTierButton">
          <div v-if="!isLoading">
            {{ switchingButtonText }}
          </div>
          <div class="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-white-500 mx-auto" v-else>
          </div>
        </a>
      </div>
      <div class="text-slate-900 dark:text-slate-200 font-medium mb-3">{{ $t('includes') }}</div>
      <ul class="text-slate-600 dark:text-slate-400 text-sm space-y-3 grow">
        <template v-for="feature in includedFeatures" :key="feature">
          <li class="flex items-center">
            <svg class="w-3 h-3 fill-emerald-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"
              v-if="feature.isActive">
              <path
                d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
            </svg>
            <svg class="w-3 h-3 fill-red-500 mr-3 shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="6 6 12 12"
              v-else>
              <path
                d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z" />
            </svg>

            <p v-html="feature.text"></p>
          </li>
        </template>
      </ul>
      <div class="text-slate-900 dark:text-slate-200 font-medium mb-3 pt-6">{{ $t('extensions') }}</div>
      <ul class="text-slate-600 dark:text-slate-400 text-sm space-y-3 grow">
        <template v-for="feature in extensionFeatures" :key="feature">
          <li class="flex items-center">
            <svg class="w-3 h-3 fill-emerald-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
            </svg>
            <p v-html="feature"></p>
          </li>
        </template>
      </ul>
      <div class="text-slate-900 dark:text-slate-200 font-medium mb-3 pt-6">Übersetzung mit euren Übersetzern:</div>
      <ul class="text-slate-600 dark:text-slate-400 text-sm space-y-3 grow">
        <template v-for="feature in interpreterFeatures" :key="feature">
          <li class="flex items-center">
            <svg class="w-3 h-3 fill-emerald-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
            </svg>
            <p v-html="feature"></p>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import windowClose from '@/assets/window-close.svg';

export default {
  props: {
    yearly: Boolean,
    popular: {
      type: Boolean,
      default: false
    },
    planName: String,
    price: Object,
    planDescription: String,
    includedFeatures: Array,
    extensionFeatures: Array,
    interpreterFeatures: Array,
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showSwitchTierButton() {
      return this.$store.state.planActive && this.planName !== 'Free' && !(this.$store.state.isAnnualSubscription && !this.yearly );
    },
    showAboButton() {
      return !this.$store.state.planActive || (this.$store.state.planActive && this.$store.state.tier === 'Free' && this.planName !== 'Free');
    },
    buttonText() {
      if (!this.$store.state.user && this.planName === 'Free') return 'Sign up';
      if (!this.planName !== 'Free' && !this.$store.state.planActive) return 'Abonnieren';
      return 'Upgrade';
    },
    switchingButtonText() {
      if (this.$store.state.tier == this.planName && this.$store.state.isAnnualSubscription == this.yearly) return 'Current';
      if (this.getPlanTierHierachy(this.$store.state.tier, this.planName) > 0) return 'Downgrade';
      return 'Upgrade';
    }
  },
  data() {
    return {
      windowClose: windowClose,
      onHover: false,
    };
  },
  methods: {
    getPlanTierHierachy(currentPlan, newPlan) {
      const planTier = {
        Free: 0,
        Base: 1,
        Pro: 2,
        Enterprise: 3,
      };
      const currentAnnualBoost = this.$store.state.isAnnualSubscription ? 10 : 0;
      const newAnnualBoost = this.yearly ? 10 : 0;
      return (planTier[currentPlan] + currentAnnualBoost) - (planTier[newPlan] + newAnnualBoost);
    },
    switchPlan() {
      if (this.$store.state.tier == this.planName && this.$store.state.isAnnualSubscription == this.yearly) return;
      this.$emit('switchPlan', this.yearly);
    }
  }
}
</script>
