<template>
    <div class="w-4/5 min-w-[500px] mx-auto grid grid-cols-1 divide-y divide-slate-300">
        <div class="ma-5 mt-10">
            <span class="text-2xl">{{ $t('feedback.feedback') }}</span>
        </div>
        <div>
            <div class="container mx-auto p-4">
                <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                    <div class="flex justify-between">
                        <h1 class="text-lg mb-4 mx-auto text-gray-800">{{ $t('feedback.titleText') }}</h1>
                    </div>
                    <div>
                        <label class="block text-gray-700 text-sm font-bold mb-2">{{ $t('feedback.name') }}</label>
                        <input v-model="name" :placeholder="$t('feedback.name')"
                            class="p-1 block w-1/4 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
                            type="text" />
                    </div>
                    <div class="mt-3">
                        <label class="block text-gray-700 text-sm font-bold mb-2">{{ $t('feedback.email') }}</label>
                        <input v-model="email" :placeholder="$t('feedback.emailAdress')"
                            class="p-1 block w-1/4 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
                            type="text" />
                    </div>
                    <div class="mt-3">
                        <label class="block text-gray-700 text-sm font-bold mb-2">{{ $t('feedback.message') }}</label>
                        <textarea v-model="messageText" :placeholder="$t('feedback.placeholderMessage')"
                            class="p-1 block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
                            rows="4"></textarea>
                    </div>
                    <div>
                        <button @click="sendFeedback" :class="buttonClass"
                            class="mt-4 py-2 px-4 rounded w-[240px] flex justify-center items-center">
                            <div v-if="!isSendingFeedback && !isFeedbackSent" class=" text-white">{{ $t('feedback.send')
                                }}</div>
                            <div v-else-if="isSendingFeedback"
                                class="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-white-500"></div>
                            <div v-else-if="isFeedbackSent" class="text-white">
                                <v-img :src="require('@/assets/check-all.svg')" class="w-[24px] h-[24px]"></v-img>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import validator from 'validator';
import { httpsCallable } from 'firebase/functions';
import { functions } from '@/firebaseInit';

export default {
    data() {
        return {
            name: '',
            email: '',
            messageText: '',
            isSendingFeedback: false,
            isFeedbackSent: false,
            sendEmail: httpsCallable(functions, 'sendEmail'),
        };
    },
    created() {
        this.sendEmail({ reduceColdStart: true });
    },
    methods: {
        async sendFeedback() {
            if (!this.name || !this.email || !this.messageText) {
                this.$store.dispatch('showSnackbar', {
                    message: this.$t('feedback.error.missingFields')
                });
                return;
            }
            if (!validator.isEmail(this.email)) {
                this.$store.dispatch('showSnackbar', {
                    message: this.$t('feedback.error.invalidEmail')
                });
                return;
            }

            this.isSendingFeedback = true;

            try {
                await this.$store.dispatch('sendEmail', {
                    name: this.name,
                    email: this.email,
                    subject: "Streamlingo User Feedback",
                    message: this.messageText
                });

                this.isFeedbackSent = true;
            } catch (error) {
                this.$store.dispatch('showSnackbar', {
                    message: this.$t('feedback.error.standardError') + error.message
                });
            } finally {
                this.isSendingFeedback = false;
            }
        },
    },
    computed: {
        buttonClass() {
            return this.isFeedbackSent ? 'bg-green-500 hover:bg-green-600' : 'bg-blue-500 hover:bg-blue-600';
        },
    },
};
</script>

<style></style>