<template>
    <div class="z-20 scroll-indicator-wrapper absolute bottom-12 left-1/2 transform -translate-x-1/2">
        <div class="flex flex-col items-center text-white space-y-2 cursor-pointer" @click="scrollToNextSection">
            <!-- Optional Text -->
            <span class="text-sm font-medium tracking-wider opacity-80">{{ $t('home.scrollToExplore') }}</span>
            
            <!-- Arrow Animation -->
            <div class="scroll-arrow-container">
                <svg 
                    class="w-6 h-6 animate-bounce" 
                    fill="none" 
                    stroke="currentColor" 
                    viewBox="0 0 24 24"
                >
                    <path 
                        stroke-linecap="round" 
                        stroke-linejoin="round" 
                        stroke-width="2" 
                        d="M19 14l-7 7m0 0l-7-7m7 7V3"
                    />
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ScrollIndicator',
    methods: {
        scrollToNextSection() {
            const videoSection = document.getElementById('video');
            if (videoSection) {
                videoSection.scrollIntoView({ 
                    behavior: 'smooth',
                    block: 'start'
                });
            }
        }
    }
}
</script>

<style scoped>
.scroll-indicator-wrapper {
    animation: fadeIn 1s ease-in forwards;
    animation-delay: 1s;
    opacity: 0;
}

.scroll-arrow-container {
    position: relative;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translate(-50%, 20px);
    }
    to {
        opacity: 1;
        transform: translate(-50%, 0);
    }
}

/* Hover-Effekt */
.scroll-indicator-wrapper:hover .scroll-arrow-container {
    transform: translateY(4px);
    transition: transform 0.2s ease;
}
</style>