<!-- AdminLanguageButton.vue -->
<template>
  <div class="admin-language-button-component border-[1px] rounded-xl flex items-center relative h-[75px] w-[1000px]"
    :class="borderColor">
    <div class="absolute left-[25px] top-[20px] w-[32px] h-[32px] flex justify-center items-center">
      <input type="checkbox" :checked="isChecked" class="w-[22px] h-[22px] border-[1px] border-slate-400 rounded-[4px]"
        @change="$emit('checkboxChanged', $event.target.checked)" :disabled="missingInformations || !editedName" />
    </div>
    <input type="text" class="text-wrapper" @input="handleInput" :placeholder="$t('channelName')" v-model="editedName"
      :autofocus="!this.editedName.length" />
    <!-- Option Dropdown -->
    <div class="relative inline-block absolute left-[280px]">
      <div @click="toggleOptionDropdown"
        class="rounded-lg border-[1px] border-slate-400 px-3 py-1 w-[200px] text-center inline-flex items-center cursor-pointer justify-between"
        :class="{ 'opacity-60': isTranslationRunning }">
        <span>{{ currentOption }}</span>
        <svg class="w-2.5 h-2.5 ms-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6"
          :class="{ 'rotate-180': isOptionDropdownOpen }">
          <path stroke="black" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m1 1 4 4 4-4" />
        </svg>
      </div>
      <!-- Dropdown Menu -->
      <div v-if="isOptionDropdownOpen" class="absolute left-0 mt-2 w-[200px] bg-white rounded-lg shadow z-10">
        <ul class="p-3 space-y-1 text-sm text-gray-700">
          <!-- Use AI Option -->
          <li @click="selectOption('useAI')"
            class="flex items-center p-2 pr-5 rounded hover:bg-gray-100 cursor-pointer">
            <span>{{ $t('useAI') }}</span>
          </li>
          <!-- Use Interpreter Option -->
          <li>
            <div class="flex items-center p-2 pr-5 rounded hover:bg-gray-100" :class="{
              'text-gray-400 cursor-not-allowed': !this.$store.state.planActive,
              'cursor-pointer': this.$store.state.planActive
            }" @click="this.$store.state.planActive ? selectOption('useInterpreter') : null">
              <span>{{ $t('useInterpreter') }}</span>
              <!-- Tooltip for Disabled Option -->
              <v-tooltip v-if="!this.$store.state.planActive" activator="parent" location="top">
                {{ $t('activatePlanForInterpreter') }}
              </v-tooltip>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="absolute top-[26px] left-[560px] text-blue-800 cursor-pointer" :class="textColor"
      @click="$emit('shareClicked')" v-if="propsCurrentOption !== 'Nutze KI'">{{ $t('shareInterpreterUrl') }}</div>
    <div v-else>
      <div class="absolute top-[5px] left-[560px] w-[250px] h-[10px] text-rose-500 cursor-pointer"
        v-if="!propsTargetLanguage">
        <v-autocomplete v-model="selectedTargetLanguage" :items="allLanguages" :label="$t('selectTargetLanguage')"
          item-title="combined_name" item-value="code" return-object variant="plain">
        </v-autocomplete>
      </div>
      <div class="absolute top-[26px] left-[560px] text-blue-800 text-blue-800 cursor-pointer" v-else
        @click="selectedTargetLanguage = null">
        {{ $t('targetLanguage') }} {{ displayName }}</div>
    </div>
    <div class="absolute top-[24px] left-[850px] text-grey">{{ $t('listeners') }} {{ currentListeners }}</div>
    <img class="absolute w-[22px] left-[955px] cursor-pointer" alt="Window close" :src="windowClose"
      @click="$emit('closeClicked')" />

    <v-tooltip v-if="showTooltip" activator="parent" location="top">
      {{ toolTipText }}
    </v-tooltip>
  </div>
</template>

<script>
import windowClose from '@/assets/window-close.svg';
import { languages_detailed } from '../languages_detailed';

export default {
  name: 'AdminLanguageButton',
  props: {
    propsIsTranslationRunning: {
      type: Boolean,
      default: false,
      required: true,
    },
    propsEditedName: {
      type: String,
      default: '',
      required: true,
    },
    propsIsChecked: {
      type: Boolean,
      default: false,
      required: true,
    },
    propsCurrentOption: {
      type: String,
      default: 'Nutze KI',
      required: true,
    },
    propsTargetLanguage: {
      type: Object,
      default: null,
    },
    propsCurrentListeners: {
      type: Number,
      default: 0,
    },
    propsIsOriginalLanguageSet: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data() {
    return {
      windowClose: windowClose,
      isOptionDropdownOpen: false,
      editedName: this.propsEditedName,
      selectedTargetLanguage: this.propsTargetLanguage,
      allLanguages: [],
      isChecked: this.propsIsChecked,
      currentListeners: this.propsCurrentListeners,
      isOriginalLanguageSet: this.propsIsOriginalLanguageSet,
      isTranslationRunning: this.propsIsTranslationRunning,
    };
  },
  created() {
    this.allLanguages = languages_detailed.map(lang => {
      return {
        code: lang.code,
        name: lang.name,
        real_name: lang.real_name,
        combined_name: lang.name + ' (' + lang.real_name + ')',
      };
    });
  },
  watch: {
    propsCurrentListeners(newValue) {
      this.currentListeners = newValue;
    },
    propsEditedName(newValue) {
      this.editedName = newValue;
    },
    propsTargetLanguage(newValue) {
      this.selectedTargetLanguage = newValue;
    },
    propsIsChecked(newValue) {
      this.isChecked = newValue;
    },
    propsIsOriginalLanguageSet(newValue) {
      this.isOriginalLanguageSet = newValue;
    },
    propsIsTranslationRunning(newValue) {
      this.isTranslationRunning = newValue;
    },
    selectedTargetLanguage(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit('targetlanguageSelected', newVal);
      }
    },
    missingInformations(newValue) {
      if (newValue) {
        this.isChecked = false;
        this.$emit('checkboxChanged', false);
      } else {
        this.isChecked = this.propsIsChecked;
      }
    },
  },
  computed: {
    currentOption() {
      if (this.propsCurrentOption !== 'Nutze KI') {
        return this.$t('useInterpreter');
      }
      return this.$t('useAI');
    },
    showTooltip() {
      return !this.editedName.length || this.missingInformations || !this.isOriginalLanguageSet;
    },
    toolTipText() {
      if (!this.editedName.length) return this.$t('tooltip.fillInChannelname');
      if (!this.isOriginalLanguageSet) return this.$t('tooltip.selectSourceLanguage');
      if (this.missingInformations) return this.$t('tooltip.selectTargetLanguage');
      return this.$t('tooltip.fillInAllFields');
    },
    borderColor() {
      return this.missingInformations ? 'border-rose-500' : 'border-slate-400';
    },
    textColor() {
      return this.missingInformations ? 'text-rose-500' : 'text-blue-800';
    },
    displayName() {
      return this.selectedTargetLanguage ? this.selectedTargetLanguage.name : this.$t('select');
    },
    missingInformations() {
      return this.editedName.length && this.propsCurrentOption === 'Nutze KI' && (!this.selectedTargetLanguage || !this.isOriginalLanguageSet);
    },
    useAutoFocus() {
      return !this.editedName.length;
    },
  },
  methods: {
    handleInput(event) {
      const inputValue = event.target.value;
      if (inputValue.length <= 12) {
        this.editedName = inputValue;
        this.$emit('nameChanged', inputValue)
      } else {
        this.editedName = inputValue.slice(0, 13);
      }
    },
    toggleOptionDropdown() {
      if (this.isTranslationRunning) return;
      this.isOptionDropdownOpen = !this.isOptionDropdownOpen;
    },
    selectOption(option) {
      if (option === 'useInterpreter' && !this.$store.state.planActive) {
        // Do nothing if plan is not active
        return;
      }
      this.$emit('optionSelected', option);
      this.isOptionDropdownOpen = false;
    },
  },
};
</script>


<style>
.admin-language-button-component .text-wrapper {
  color: #000000;
  font-family: "Inter-Medium", Helvetica;
  font-size: 25px;
  font-weight: 500;
  left: 82px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 20px;
  white-space: nowrap;
  border: none;
  background-color: transparent;
  outline: none;
}
</style>