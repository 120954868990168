<template>
    <div class="w-4/5 mx-auto grid grid-cols-1 gap-8">
        <div class="text-2xl font-bold mt-10">{{ $t('settings.settings') }}</div>

        <!-- Allgemeine Einstellungen -->
        <div class="bg-white shadow-md rounded-lg px-6 py-6">
            <div class="flex justify-between items-center mb-4">
                <h3 class="text-xl font-semibold">{{ $t('settings.generalSettings') }}</h3>
            </div>
            <div class="grid gap-4">
                <div class="grid grid-cols-[230px_1fr] items-center">
                    <div>{{ $t('settings.conferenceName') }}</div>
                    <div class="flex items-center">
                        <input v-model="form.conferenceName"
                            class="border border-gray-300 text-sky-600 focus:outline-none focus:ring-2 focus:ring-sky-500 rounded px-2 py-1 w-full max-w-xs" />
                    </div>
                </div>
                <div class="grid grid-cols-[230px_1fr] items-center">
                    <div>{{ $t('settings.conferenceURL') }}</div>
                    <div class="flex items-center">
                        <span class="mr-1">{{ frontendUrl }}/listen/</span>
                        <input v-model="form.conferenceUrl"
                            class="border border-gray-300 text-sky-600 focus:outline-none focus:ring-2 focus:ring-sky-500 rounded px-2 py-1 w-40" />
                        <button
                            @click="$store.dispatch('showQRModal', { link: qrCodeLink, text: $t('settings.conferenceURL') })"
                            class="ml-2 text-sky-600 hover:text-sky-800 hover:bg-slate-100 px-3 py-1 rounded-lg">
                            {{ $t('settings.showQRCode') }}
                        </button>
                    </div>
                </div>
                <div class="grid grid-cols-[230px_1fr] items-center">
                    <div>{{ $t('settings.languageSettings') }}</div>
                    <div class="relative inline-block">
                        <div @click="isLanguageSelectionDropdownOpen = !isLanguageSelectionDropdownOpen"
                            class="rounded-lg border px-3 py-1 w-fit text-center inline-flex items-center cursor-pointer justify-between">
                            <span>{{ $t('language') }}</span>
                            <svg class="w-2.5 h-2.5 ms-3" xmlns="http://www.w3.org/2000/svg" fill="none"
                                viewBox="0 0 10 6" :class="{ 'rotate-180': isLanguageSelectionDropdownOpen }">
                                <path stroke="black" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                                    d="m1 1 4 4 4-4" />
                            </svg>
                        </div>
                        <div v-if="isLanguageSelectionDropdownOpen"
                            class="absolute left-0 mt-2 w-fit bg-white rounded-lg shadow z-10">
                            <ul class="p-3 space-y-1 text-sm text-gray-700">
                                <li v-for="(language, index) in supportedLanguages" :key="index">
                                    <div @click="changeLanguage(language)"
                                        class="flex items-center p-2 pr-8 rounded hover:bg-gray-100 cursor-pointer">
                                        <span>{{ language.name }}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- Neue Einstellung für die Stimmauswahl -->
                <div class="grid grid-cols-[230px_1fr] items-center">
                    <div>{{ $t('settings.voiceSelection') }}</div>
                    <div class="relative inline-block">
                        <div @click="isVoiceSelectionDropdownOpen = !isVoiceSelectionDropdownOpen"
                            class="rounded-lg border px-3 py-1 w-fit text-center inline-flex items-center cursor-pointer justify-between">
                            <span>{{ form.useFemaleTTS ? $t('settings.femaleVoice') : $t('settings.maleVoice') }}</span>
                            <svg class="w-2.5 h-2.5 ms-3" xmlns="http://www.w3.org/2000/svg" fill="none"
                                viewBox="0 0 10 6" :class="{ 'rotate-180': isVoiceSelectionDropdownOpen }">
                                <path stroke="black" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                                    d="m1 1 4 4 4-4" />
                            </svg>
                        </div>
                        <div v-if="isVoiceSelectionDropdownOpen"
                            class="absolute left-0 mt-2 w-44 bg-white rounded-lg shadow z-10">
                            <ul class="p-3 space-y-1 text-sm text-gray-700">
                                <li>
                                    <div @click="selectVoice(true)"
                                        class="flex items-center p-2 rounded hover:bg-gray-100 cursor-pointer">
                                        <span>{{ $t('settings.femaleVoice') }}</span>
                                    </div>
                                </li>
                                <li>
                                    <div @click="selectVoice(false)"
                                        class="flex items-center p-2 rounded hover:bg-gray-100 cursor-pointer">
                                        <span>{{ $t('settings.maleVoice') }}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- Einstellungsoption um zwischen Deepl und Google Translate zu wechseln, user.useDeepl -->
                <!-- <div class="grid grid-cols-[230px_1fr] items-center">
                    <div>{{ $t('settings.translationService') }}</div>
                    <div class="relative inline-block">
                        <div @click="isTranslationServiceDropdownOpen = !isTranslationServiceDropdownOpen"
                            class="rounded-lg border px-3 py-1 w-fit text-center inline-flex items-center cursor-pointer justify-between">
                            <span>{{ form.useDeepl ? $t('settings.deepl') : $t('settings.googleTranslate') }}</span>
                            <svg class="w-2.5 h-2.5 ms-3" xmlns="http://www.w3.org/2000/svg" fill="none"
                                viewBox="0 0 10 6" :class="{ 'rotate-180': isTranslationServiceDropdownOpen }">
                                <path stroke="black" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                                    d="m1 1 4 4 4-4" />
                            </svg>
                        </div>
                        <div v-if="isTranslationServiceDropdownOpen"
                            class="absolute left-0 mt-2 w-44 bg-white rounded-lg shadow z-10">
                            <ul class="p-3 space-y-1 text-sm text-gray-700">
                                <li>
                                    <div @click="selectTranslationService(true)"
                                        class="flex items-center p-2 rounded hover:bg-gray-100 cursor-pointer">
                                        <span>{{ $t('settings.deepl') }}</span>
                                    </div>
                                </li>
                                <li>
                                    <div @click="selectTranslationService(false)"
                                        class="flex items-center p-2 rounded hover:bg-gray-100 cursor-pointer">
                                        <span>{{ $t('settings.googleTranslate') }}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div> -->
                <!-- New Inactivity Timer Settings -->
                <div class="grid grid-cols-[230px_1fr] items-center">
                    <div class="flex items-center">
                        {{ $t('settings.inactivityTime') }}
                        <v-tooltip location="top">
                            <template v-slot:activator="{ props }">
                                <v-icon v-bind="props" class="ml-1 text-gray-400 hover:text-gray-600"
                                    icon="mdi-help-circle-outline" />
                            </template>
                            <span class="text-blue-500">
                                {{ $t('settings.inactivityTimeTooltip') }}
                            </span>
                        </v-tooltip>
                    </div>
                    <div class="flex items-center">
                        <input v-model="form.inactivityTimeInMinutes" type="number" min="1" max="60"
                            class="border border-gray-300 text-sky-600 focus:outline-none focus:ring-2 focus:ring-sky-500 rounded px-2 py-1 w-20" />
                        <span class="ml-2">{{ $t('settings.minutes') }}</span>
                    </div>
                </div>
                <div class="grid grid-cols-[230px_1fr] items-center">
                    <div class="flex items-center">
                        {{ $t('settings.warningTime') }}
                        <v-tooltip location="top">
                            <template v-slot:activator="{ props }">
                                <v-icon v-bind="props" class="ml-1 text-gray-400 hover:text-gray-600"
                                    icon="mdi-help-circle-outline" />
                            </template>
                            <span class="text-blue-500">
                                {{ $t('settings.warningTimeTooltip') }}
                            </span>
                        </v-tooltip>
                    </div>
                    <div class="flex items-center">
                        <input v-model="form.warningTimeInMinutes" type="number" min="1" max="60"
                            class="border border-gray-300 text-sky-600 focus:outline-none focus:ring-2 focus:ring-sky-500 rounded px-2 py-1 w-20" />
                        <span class="ml-2">{{ $t('settings.minutes') }}</span>
                    </div>
                </div>
                <!-- Set Speaker Pause Timer for short utterances -->
                <div class="grid grid-cols-[230px_1fr] items-center">
                    <div class="flex flex-wrap items-center">
                        {{ $t('settings.speechRecognitionSensitivity') }}
                        <v-tooltip location="top">
                            <template v-slot:activator="{ props }">
                                <v-icon v-bind="props" class="ml-1 text-gray-400 hover:text-gray-600"
                                    icon="mdi-help-circle-outline" />
                            </template>
                            <span class="text-blue-500">
                                {{ $t('settings.speechRecognitionSensitivityTooltip') }}
                            </span>
                        </v-tooltip>
                    </div>
                    <div class="relative inline-block">
                        <div @click="isSensitivityDropdownOpen = !isSensitivityDropdownOpen"
                            class="rounded-lg border px-3 py-1 w-fit text-center inline-flex items-center cursor-pointer justify-between">
                            <span>{{ $t(`settings.${speechRecognitionSensitivity}`) }}</span>
                            <svg class="w-2.5 h-2.5 ms-3" xmlns="http://www.w3.org/2000/svg" fill="none"
                                viewBox="0 0 10 6" :class="{ 'rotate-180': isSensitivityDropdownOpen }">
                                <path stroke="black" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                                    d="m1 1 4 4 4-4" />
                            </svg>
                        </div>
                        <div v-if="isSensitivityDropdownOpen"
                            class="absolute left-0 mt-2 w-fit bg-white rounded-lg shadow z-10">
                            <ul class="p-3 space-y-1 text-sm text-gray-700">
                                <li v-for="option in ['veryHigh', 'high', 'medium', 'low']" :key="option">
                                    <div @click="selectSensitivity(option)"
                                        class="flex items-center p-2 rounded hover:bg-gray-100 cursor-pointer">
                                        <span>{{ $t(`settings.${option}`) }}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>

            <div class="mt-4 flex justify-end space-x-4">
                <button @click="discardChanges"
                    class="text-sky-600 hover:text-sky-800 hover:bg-slate-100 px-4 py-2 rounded-lg">
                    {{ $t('settings.discard') }}
                </button>
                <button @click="saveChanges" class="bg-sky-600 text-white hover:bg-sky-700 px-4 py-2 rounded-lg">
                    {{ $t('settings.save') }}
                </button>
            </div>
        </div>

        <!-- Profil -->
        <div class="bg-white shadow-md rounded-lg px-6 py-6">
            <h3 class="text-xl font-semibold mb-4">{{ $t('settings.profile') }}</h3>
            <div class="grid gap-4">
                <div class="grid grid-cols-[230px_1fr] items-center">
                    <div>{{ $t('settings.email') }}</div>
                    <span>{{ email }}</span>
                </div>
                <div class="grid grid-cols-[230px_1fr] items-center">
                    <div>{{ $t('settings.password') }}</div>
                    <button @click="resetPassword"
                        class="text-sky-600 hover:text-sky-800 border hover:bg-slate-100 px-3 py-1 rounded-lg w-fit">
                        {{ $t('settings.reset') }}
                    </button>
                </div>
                <div v-if="!phoneNumberVerified" class="grid grid-cols-[230px_1fr] items-center">
                    <div>{{ $t('settings.phonenumber') }}</div>
                    <div class="flex items-center">
                        <input v-model="phoneNumber" type="text" placeholder="+491234567890"
                            class="border border-gray-300 focus:outline-none focus:ring-2 focus:ring-sky-500 rounded px-2 py-1 w-48" />
                        <button @click="verifyPhoneNumber"
                            class="ml-2 text-sky-600 hover:text-sky-800 border hover:bg-slate-100 px-3 py-1 rounded-lg">
                            {{ $t('settings.verify') }}
                        </button>
                    </div>
                </div>
                <div v-if="!phoneNumberVerified">
                    <div id="recaptcha-container" class="mt-4"></div>
                </div>
                <div v-if="showCodeInput && !phoneNumberVerified" class="grid grid-cols-[230px_1fr_auto] items-center">
                    <div>{{ $t('settings.verificationCode') }}</div>
                    <input v-model="verificationCode" type="text" placeholder="123456"
                        class="focus:outline-none border rounded px-2 py-1" />
                    <button @click="confirmVerificationCode"
                        class="text-sky-600 hover:text-sky-800 border hover:bg-slate-100 px-3 py-1 rounded-lg">
                        {{ $t('settings.confirm') }}
                    </button>
                </div>
                <div v-if="phoneNumberVerified" class="grid grid-cols-[230px_1fr] items-center">
                    <div>{{ $t('settings.phonenumber') }}</div>
                    <span>{{ phoneNumber }}</span>
                </div>
            </div>
        </div>

        <!-- Nutzungskontingent -->
        <div v-if="usageQuota && form.planActive" class="bg-white shadow-md rounded-lg px-6 py-6">
            <h3 class="text-xl font-semibold mb-4">{{ $t('settings.usageQuota') }}</h3>
            <div class="grid gap-4">
                <div class="grid grid-cols-[230px_1fr] items-center">
                    <div>{{ $t('settings.billingPeriod') }}:</div>
                    <div>{{ new Date(usageQuota.billingPeriod.start).toLocaleDateString(this.localLanguage, {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit'
                    }) }} - {{ new
                            Date(usageQuota.billingPeriod.end).toLocaleDateString(this.localLanguage, {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit'
                            }) }}
                    </div>
                </div>
                <div class="grid grid-cols-[230px_1fr] items-center">
                    <div>{{ $t('settings.aiTranslation') }}:</div>
                    <div>
                        <div class="flex items-center">
                            <div class="w-4/5 bg-gray-200 rounded-full h-2.5 mr-2">
                                <div class="bg-blue-600 h-2.5 rounded-full"
                                    :style="{ width: `${Math.min((usageQuota.usedAIQuota / usageQuota.totalAIQuota) * 100, 100)}%` }">
                                </div>
                            </div>
                            <span class="text-sm">{{ Math.round(usageQuota.usedAIQuota / 60) }} / {{
                                Math.round(usageQuota.totalAIQuota / 60) }} {{ $t('settings.minutes') }}</span>
                        </div>
                        <div v-if="usageQuota.usedAIQuota > usageQuota.totalAIQuota" class="text-sm text-gray-600 mt-1">
                            {{ $t('settings.additionalUsage') }}: {{ Math.round((usageQuota.usedAIQuota -
                                usageQuota.totalAIQuota) / 60) }} {{ $t('settings.minutes') }}
                        </div>
                    </div>
                </div>
                <div class="grid grid-cols-[230px_1fr] items-center">
                    <div>{{ $t('settings.interpreterListeners') }}:</div>
                    <div>
                        <div class="flex items-center">
                            <div class="w-4/5 bg-gray-200 rounded-full h-2.5 mr-2">
                                <div class="bg-green-600 h-2.5 rounded-full"
                                    :style="{ width: `${Math.min((usageQuota.usedInterpreterQuota / usageQuota.totalInterpreterQuota) * 100, 100)}%` }">
                                </div>
                            </div>
                            <span class="text-sm">{{ Math.round(usageQuota.usedInterpreterQuota / 60) }} {{
                                $t('settings.minutes') }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4 text-sm text-gray-600" v-html="quotaExplanation"></div>
        </div>

        <!-- Abonnement -->
        <div class="bg-white shadow-md rounded-lg px-6 py-6 mb-20">
            <h3 class="text-xl font-semibold mb-4">{{ $t('settings.subscription') }}</h3>
            <div class="grid gap-4">
                <div class="grid grid-cols-[230px_1fr] items-center">
                    <div>{{ $t('settings.freeCredit') }}</div>
                    <!-- <span v-if="!phoneNumberVerified">{{ $t('settings.getCreditText') }}</span> -->
                    <span v-if="userInformation.freeCredits < 10" class="text-red-600">{{
                        $t('settings.consumed') }}</span>
                    <span v-else>{{ Math.round(userInformation.freeCredits / 60) }} {{ $t('settings.minutes')
                        }}</span>
                </div>
                <div class="grid grid-cols-[230px_1fr] items-center">
                    <div>{{ $t('settings.subscriptionPlan') }}</div>
                    <span>{{ userInformation.subscription }}</span>
                </div>
                <div v-if="userInformation.subscription === 'Free' && !userInformation.planActive"
                    class="grid grid-cols-[230px_1fr] items-center">
                    <div>{{ $t('settings.depositPaymentText1') }}</div>
                    <button @click="subscribeFreePlan"
                        class="w-fit text-sky-600 hover:text-sky-800 border hover:bg-slate-100 px-3 py-1 rounded-lg">
                        {{ $t('settings.depositPaymentText2') }}
                    </button>
                </div>
                <!-- <div v-if="(userInformation.subscription !== 'Enterprise' && !$store.state.planActive) || ($store.state.planActive && $store.state.tier === 'Free')"
                    class="grid grid-cols-[230px_1fr] items-center">
                    <div>{{ $t('settings.upgradeSubsciption') }}</div>
                    <div class="flex space-x-2">
                        <button v-if="userInformation.subscription === 'Free'" @click="upgradeAboTo('Base')"
                            class="text-sky-600 hover:text-sky-800 border hover:bg-slate-100 px-3 py-1 rounded-lg">
                            Base
                        </button>
                        <button
                            v-if="userInformation.subscription === 'Free' || userInformation.subscription === 'Base'"
                            @click="upgradeAboTo('Pro')"
                            class="text-sky-600 hover:text-sky-800 border hover:bg-slate-100 px-3 py-1 rounded-lg">
                            Pro
                        </button>
                        <button @click="upgradeAboTo('Enterprise')"
                            class="text-sky-600 hover:text-sky-800 border hover:bg-slate-100 px-3 py-1 rounded-lg">
                            Enterprise
                        </button>
                    </div>
                </div> -->
                <div v-if="!planExpires && userInformation.subscription !== 'Free'"
                    class="grid grid-cols-[230px_1fr] items-center">
                    <div>{{ $t('settings.cancelSubscription') }}</div>
                    <button v-if="earlestCancellationDate" @click="cancelSubscription"
                        class="text-sky-600 hover:text-sky-800 border hover:bg-slate-100 px-3 py-1 rounded-lg w-fit">
                        {{ $t('settings.cancelAt') }} {{
                            earlestCancellationDate.toLocaleDateString(this.localLanguage,
                                {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit'
                                }) }}
                    </button>
                </div>
                <div v-if="planExpires && userInformation.subscription !== 'Free'"
                    class="grid grid-cols-[230px_1fr_auto] items-center">
                    <div class="text-red-600">{{ $t('settings.subscriptionCanceldAt') }}</div>
                    <div>{{ planExpires.toLocaleDateString(this.localLanguage, {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit'
                    }) }}</div>
                    <button v-if="earlestCancellationDate" @click="continueSubscription"
                        class="text-sky-600 hover:text-sky-800 border hover:bg-slate-100 px-3 py-1 rounded-lg">
                        {{ $t('settings.continueSubscription') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LogoMitSchrift from '@/assets/Logo mit schrift.png';
import { rtdb, functions, auth } from '@/firebaseInit';
import { get, child, ref, update, set, remove } from 'firebase/database';
import { RecaptchaVerifier, getAuth, linkWithPhoneNumber, sendPasswordResetEmail } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { supportedLanguages } from '../languages/i18n';

export default {
    data() {
        return {
            LogoMitSchrift: LogoMitSchrift,
            frontendUrl: window.location.origin,
            email: this.$store.state.user.email,
            mailVerified: this.$store.state.user.emailVerified,
            phoneNumberVerified: this.$store.state.user.phoneNumber ? true : false,
            phoneNumber: this.$store.state.user.phoneNumber ? this.$store.state.user.phoneNumber : '',
            earlestCancellationDate: '',
            subscriptionId: '',
            planExpires: '',
            userInformation: {
                conferenceName: '',
                conferenceUrl: '',
                conferenceId: '',
                subscription: '',
                freeCredits: 0,
                planActive: false,
                useFemaleTTS: true,
                useDeepl: false,
                inactivityTimeInMinutes: 10,
                warningTimeInMinutes: 5,
                segmentationTimeout: 225,
            },
            form: {
                conferenceName: '',
                conferenceUrl: '',
                conferenceId: '',
                subscription: '',
                freeCredits: 0,
                planActive: false,
                useFemaleTTS: true,
                useDeepl: false,
                inactivityTimeInMinutes: 10,
                warningTimeInMinutes: 5,
                segmentationTimeout: 225,
            },
            verificationCode: '',
            showCodeInput: false,
            confirmationResult: null,
            isLanguageSelectionDropdownOpen: false,
            supportedLanguages: supportedLanguages,
            usageQuota: null,
            isTranslationServiceDropdownOpen: false,
            isTranscriptionMethodDropdownOpen: false,
            isSensitivityDropdownOpen: false,
            isFrequencyDropdownOpen: false,
            isVoiceSelectionDropdownOpen: false,
            cancelPlan: httpsCallable(functions, 'cancelPlan'),
            continuePlan: httpsCallable(functions, 'continuePlan'),
            // getFreeCredits: httpsCallable(functions, 'getFreeCredits'),
            cancelPlanImmediate: httpsCallable(functions, 'cancelPlanImmediate'),
        };
    },
    created() {
        this.getUserInformation();
        this.fetchUsageQuota();
        this.cancelPlan({ reduceColdStart: true });
        this.continuePlan({ reduceColdStart: true });
        // this.getFreeCredits({ reduceColdStart: true });
        this.cancelPlanImmediate({ reduceColdStart: true });
    },
    computed: {
        qrCodeLink() {
            return `${this.frontendUrl}/listen/${this.userInformation.conferenceUrl}`;
        },
        quotaExplanation() {
            const quota = this.getQuotaForSubscription(this.userInformation.subscription);
            return this.$t('settings.quotaExplanation').replace('_quota_', quota);
        },
        localLanguage() {
            return this.$i18n.locale === 'de' ? 'de-DE' : 'en-US'
        },
        speechRecognitionSensitivity: {
            get() {
                const valueMap = {
                    150: 'veryHigh',
                    225: 'high',
                    300: 'medium',
                    375: 'low',
                };
                return valueMap[this.form.segmentationTimeout] || 'high';
            },
            set(value) {
                const sensitivityMap = {
                    veryHigh: 150,
                    high: 225,
                    medium: 300,
                    low: 375,
                };
                this.form.segmentationTimeout = sensitivityMap[value];
            }
        },
    },
    methods: {
        selectVoice(useFemaleTTS) {
            this.form.useFemaleTTS = useFemaleTTS;
            this.isVoiceSelectionDropdownOpen = false;
        },
        selectSensitivity(option) {
            this.speechRecognitionSensitivity = option;
            this.isSensitivityDropdownOpen = false;
        },
        selectTranslationService(useDeepl) {
            this.form.useDeepl = useDeepl;
            this.isTranslationServiceDropdownOpen = false;
        },
        getQuotaForSubscription(subscription) {
            const quotas = {
                'Free': 0,
                'Base': 5,
                'Pro': 15,
                'Enterprise': 40
            };
            return quotas[subscription] || 0;
        },
        async fetchUsageQuota() {
            try {
                const getUsageQuota = httpsCallable(functions, 'getUsageQuota');
                const result = await getUsageQuota();
                this.usageQuota = result.data;
            } catch (error) {
                console.error('Error fetching usage quota:', error);
                this.$store.dispatch('showSnackbar', { message: this.$t('settings.errorFetchingQuota') });
            }
        },
        async changeLanguage(language) {
            try {
                await update(ref(rtdb, `users/${this.$store.state.user.uid}`), { language: language.locale });
                this.$store.commit('setLanguage', language.locale);
            } catch (error) {
                console.error('Error saving language preference:', error);
                this.$store.dispatch('showSnackbar', { message: this.$t('settings.errorSavingLanguage') });
            }
            this.isLanguageSelectionDropdownOpen = false;
        },
        async upgradeAboTo(plan) {
            if (!this.$store.state.planActive) {
                await this.$store.dispatch('checkoutPlan', { plan: plan, isAnnual: true, cancelUrl: this.$route.path });
            } else {
                await this.cancelPlanImmediate({ subscriptionId: this.subscriptionId });
                await this.$store.dispatch('checkoutPlan', { plan: plan, isAnnual: true, cancelUrl: this.$route.path });
            }
        },
        async subscribeFreePlan() {
            await this.$store.dispatch('checkoutPlan', { plan: 'Free', isAnnual: true, cancelUrl: this.$route.path });
        },
        async resetPassword() {
            try {
                await sendPasswordResetEmail(auth, this.$store.state.user.email);
                this.$store.dispatch('showSnackbar', { message: this.$t('settings.resetEmailWasSent') });
            } catch (error) {
                this.$store.dispatch('showSnackbar', { message: this.$t('settings.resetEmailError') });
            }
        },
        cancelSubscription() {
            this.cancelPlan({ planExpires: this.earlestCancellationDate.getTime() / 1000, subscriptionId: this.subscriptionId })
                .then(() => { window.location.reload(); })
                .catch((error) => {
                    console.error(error);
                });
        },
        continueSubscription() {
            this.continuePlan({ subscriptionId: this.subscriptionId })
                .then(() => { window.location.reload(); })
                .catch((error) => {
                    console.error(error);
                });
        },
        verifyPhoneNumber() {
            const auth = getAuth();
            const appVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {});
            linkWithPhoneNumber(this.$store.state.user, this.phoneNumber.replace(/\s/g, ''), appVerifier)
                .then((confirmationResult) => {
                    this.confirmationResult = confirmationResult;
                    this.showCodeInput = true;
                })
                .catch((error) => {
                    console.error('Fehler bei der Telefonnummer-Verifizierung:', error);
                });
        },
        confirmVerificationCode() {
            this.confirmationResult.confirm(this.verificationCode)
                .then(() => {
                    this.showCodeInput = false;
                    // this.getFreeCredits().then(() => { window.location.reload(); })
                })
                .catch((error) => {
                    console.error('Fehler bei der Telefonnummer-Verifizierung:', error);
                });
        },
        getUserInformation() {
            get(child(ref(rtdb), `users/${this.$store.state.user.uid}`)).then((snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    this.userInformation.conferenceName = data.conferenceName;
                    this.userInformation.conferenceUrl = data.conferenceUrl;
                    this.userInformation.conferenceId = data.conferenceId;
                    this.userInformation.subscription = data.subscription;
                    this.userInformation.freeCredits = data.freeCredits;
                    this.userInformation.planActive = data.planActive;
                    this.userInformation.useFemaleTTS = data.useFemaleTTS !== undefined ? data.useFemaleTTS : true;
                    this.userInformation.useDeepl = data.useDeepl !== undefined ? data.useDeepl : true;
                    this.userInformation.inactivityTimeInMinutes = data.inactivityTimeInMinutes || 10;
                    this.userInformation.warningTimeInMinutes = data.warningTimeInMinutes || 5;
                    this.userInformation.segmentationTimeout = data.segmentationTimeout || 225;
                    this.subscriptionId = data.subscriptionId;
                    const isAnnualSubscription = Boolean(data.isAnnualSubscription);
                    this.earlestCancellationDate = new Date(data.subscriptionStart * 1000);
                    this.planExpires = data.planExpires ? new Date(data.planExpires * 1000) : '';

                    while (this.earlestCancellationDate < new Date()) {
                        if (isAnnualSubscription) {
                            this.earlestCancellationDate.setFullYear(this.earlestCancellationDate.getFullYear() + 1);
                        } else {
                            this.earlestCancellationDate.setMonth(this.earlestCancellationDate.getMonth() + 1);
                        }
                    }

                    this.form = { ...this.userInformation };
                } else {
                    console.log('Keine Daten verfügbar');
                }
            }).catch((error) => {
                console.error(error);
            });
        },
        discardChanges() {
            this.form = { ...this.userInformation };
        },
        async updateUrlMapping() {
            try {
                const oldMappingRef = ref(rtdb, `urlMapping/${this.userInformation.conferenceUrl}`);
                const newMappingRef = ref(rtdb, `urlMapping/${this.form.conferenceUrl}`);

                const snapshot = await get(newMappingRef);
                if (snapshot.exists() && this.form.conferenceUrl !== this.userInformation.conferenceUrl) {
                    console.error('Die URL ist bereits vergeben');
                } else {
                    try {
                        await remove(oldMappingRef);
                        await set(newMappingRef, { conferenceId: this.form.conferenceId });
                        await update(ref(rtdb, `users/${this.$store.state.user.uid}`), { conferenceUrl: this.form.conferenceUrl })
                        await update(ref(rtdb, `conferences/${this.userInformation.conferenceId}`), { conferenceUrl: this.form.conferenceUrl });
                    } catch (error) {
                        // Rollback changes in case of an error
                        await set(oldMappingRef, { conferenceId: this.userInformation.conferenceId });
                        await remove(newMappingRef);
                        await update(ref(rtdb, `users/${this.$store.state.user.uid}`), { conferenceUrl: this.userInformation.conferenceUrl })
                        await update(ref(rtdb, `conferences/${this.userInformation.conferenceId}`), { conferenceUrl: this.userInformation.conferenceUrl });
                        throw error;
                    }
                }
            } catch (error) {
                console.error('Fehler: ', error);
            }
        },
        async updateConferenceName() {
            try {
                await update(ref(rtdb, `users/${this.$store.state.user.uid}`), { conferenceName: this.form.conferenceName })
                await update(ref(rtdb, `conferences/${this.userInformation.conferenceId}`), { conferenceName: this.form.conferenceName })
            } catch (error) {
                console.error('Fehler: ', error);
            }
        },
        async saveChanges() {
            try {
                if (this.form.conferenceUrl !== this.userInformation.conferenceUrl) await this.updateUrlMapping();
                if (this.form.conferenceName !== this.userInformation.conferenceName) await this.updateConferenceName();
                await update(ref(rtdb, `users/${this.$store.state.user.uid}`), {
                    useFemaleTTS: this.form.useFemaleTTS,
                    useDeepl: this.form.useDeepl,
                    inactivityTimeInMinutes: this.form.inactivityTimeInMinutes,
                    warningTimeInMinutes: this.form.warningTimeInMinutes,
                    segmentationTimeout: this.form.segmentationTimeout,
                });
            } finally {
                this.getUserInformation();
            }
        },
    },
};
</script>

<style></style>