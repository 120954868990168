import { createI18n } from 'vue-i18n';

// Importiere deine Sprachdateien
import en from './en.json';
import de from './de.json';

const messages = {
  en,
  de,
};

const supportedLocales = Object.keys(messages);

const supportedLanguages = supportedLocales.map((locale) => ({
  locale,
  name: messages[locale].language,
}));

// Ermittle die bevorzugte Sprache des Nutzers
const userLocale = navigator.language || navigator.userLanguage;

// Definiere eine Funktion, um die bevorzugte Sprache zu ermitteln
function getUserLocale() {
  const locale = userLocale.split('-')[0]; // Nur den Sprachcode verwenden (z.B. 'en' von 'en-US')
  return messages[locale] ? locale : 'en'; // Überprüfe, ob die Sprache unterstützt wird, ansonsten fallback auf 'en'
}

const i18n = createI18n({
  locale: getUserLocale(),
  fallbackLocale: 'en',
  messages,
});

export { supportedLocales, supportedLanguages };
export default i18n;
