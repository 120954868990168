// router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import ListenerView from '../components/mobile/ListenerView.vue';
import SpeakerPage from '../components/mobile/SpeakerPage.vue';
import DashboardComponent from '../components/DashboardComponent.vue';
import SettingsPage from '../components/SettingsPage.vue';
import ComingSoonPage from '../components/ComingSoonPage.vue';
import UsageView from '../components/UsageView.vue';
import VueTemplate from '../components/VueTemplate.vue';
import MobileDashboardView from '../components/MobileDashboardView.vue';
import AGBPage from '../components/AGBPage.vue';
import PricingPage from '../components/PricingPage.vue';
import ImpressumPage from '../components/ImpressumPage.vue';
import FAQPage from '../components/FAQPage.vue';
import LandingPage from '../components/LandingPage.vue';
import FurtherFunctions from '../components/FurtherFunctions.vue';
import FeedbackPage from '../components/FeedbackPage.vue';
import store from '../store/index';

const routes = [
    {
        path: '/',
        name: 'landingpage',
        component: LandingPage,
        meta: { requiresAuth: false },
    },
    {
        path: '/company',
        name: 'company',
        component: () => import('../components/CompanyPage.vue'),
        meta: { requiresAuth: false },
    },
    {
        path: '/services',
        name: 'services',
        component: () => import('../components/ServicesPage.vue'),
    },
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: () => import('../components/PrivacyPolicyPage.vue'),
    },
    {
        path: '/cookie-policy',
        name: 'CookiePolicy',
        component: () => import('../components/CookiePolicyPage.vue'),
    },
    {
        path: '/listen/:conferenceUrl',
        name: 'listen',
        component: ListenerView,
        meta: { requiresAuth: false },
        props: true,
    },
    {
        path: '/speaker/:speakerId',
        name: 'speaker',
        component: SpeakerPage,
        meta: { requiresAuth: false },
        props: true,
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: DashboardComponent,
        meta: { requiresAuth: true },
    },
    {
        path: '/further-functions',
        name: 'further-functions',
        component: FurtherFunctions,
        meta: { requiresAuth: true },
    },
    {
        path: '/settings',
        name: 'settings',
        component: SettingsPage,
        meta: { requiresAuth: true },
    },
    {
        path: '/comingsoon',
        name: 'comingsoon',
        component: ComingSoonPage,
        meta: { requiresAuth: false },
    },
    {
        path: '/usage',
        name: 'usage',
        component: UsageView,
        meta: { requiresAuth: true },
    },
    {
        path: '/mobile-dashboard',
        name: 'mobileDashboard',
        component: MobileDashboardView,
        meta: { requiresAuth: true },
    },
    {
        path: '/pricing',
        name: 'pricing',
        component: PricingPage,
        meta: { requiresAuth: false },
    },
    {
        path: '/impressum',
        name: 'impressum',
        component: ImpressumPage,
        meta: { requiresAuth: false },
    },
    {
        path: '/faq',
        name: 'faq',
        component: FAQPage,
        meta: { requiresAuth: false },
    },
    {
        path: '/terms-and-conditions',
        name: 'agb',
        component: AGBPage,
        meta: { requiresAuth: false },
    },
    {
        path: '/feedback',
        name: 'feedback',
        component: FeedbackPage,
        meta: { requiresAuth: false },
    },
    {
        path: '/template',
        name: 'template',
        component: VueTemplate,
        meta: { requiresAuth: false },
    },
    {
        path: '/:catchAll(.*)', // Fängt alle nicht definierten Routen
        redirect: '/',
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async (to, from, next) => {
    // Prüfen, ob die Route eine Authentifizierung erfordert und ob der Benutzer authentifiziert ist
    while (!store.state.authIsReady) await new Promise(resolve => setTimeout(resolve, 50));
    if (to.matched.some(record => record.meta.requiresAuth) && !store.state.user) {
        next({ name: 'landingpage' });
    } else {
        if (to.name === 'dashboard' && store.state.user && window.innerWidth < 768) {
            next({ name: 'mobileDashboard' });
        } else {
            next();
        }
    }
});

export default router;
