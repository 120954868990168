<template>
    <transition name="modal">
      <div v-if="show" class="fixed z-50 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" @click="close"></div>
  
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
  
          <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div class="sm:flex sm:items-start">
                <div class="mt-3 text-center sm:mt-0 sm:my-4 sm:text-left">
                  <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                    {{ $t('confirmPlanChange.planChangeTitle', { plan: newPlan }) }}
                  </h3>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">
                      {{ $t('confirmPlanChange.planChangeDescription', { plan: newPlan, period: isAnnual ? $t('confirmPlanChange.annually') : $t('confirmPlanChange.monthly') }) }}
                    </p>
                    <p class="text-sm text-gray-500 mt-2">
                      {{ $t('confirmPlanChange.unusedQuotaInfo') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-white px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button type="button" @click="confirm" class="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm transition duration-150 ease-in-out">
                {{ $t('confirmPlanChange.confirmPlanChange') }}
              </button>
              <button type="button" @click="close" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm transition duration-150 ease-in-out">
                {{ $t('confirmPlanChange.cancelPlanChange') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  export default {
    name: 'ConfirmPlanChangeModal',
    props: {
      show: Boolean,
      newPlan: String,
      isAnnual: Boolean,
    },
    methods: {
      close() {
        this.$emit('close');
      },
      confirm() {
        this.$emit('confirm', this.newPlan, this.isAnnual);
      },
    },
  };
  </script>
  
  <style scoped>
  .modal-enter-active, .modal-leave-active {
    transition: all 0.3s ease;
  }
  .modal-enter, .modal-leave-to {
    opacity: 0;
    transform: scale(0.9);
  }
  </style>