<!-- OriginalLanguageButton.vue -->
<template>
  <div class="original-language-button-component border-[1px] rounded-xl flex items-center relative h-[75px] w-[1000px]"
    :class="borderColor">
    <div class="checkbox-container" v-if="this.$store.state.planActive">
      <input type="checkbox" :checked="isChecked" class="w-[22px] h-[22px] border-[1px] border-slate-400 rounded-[4px]"
        @change="$emit('checkboxChanged', $event.target.checked)" :disabled="!allowCheckbox" />
      <v-tooltip v-if="showCheckboxTooltip" activator="parent" location="top" class="z-20">
        {{ $t('pleaseActivatePlan') }}
      </v-tooltip>
    </div>
    <input type="text" class="text-wrapper" @input="handleInput" :placeholder="$t('channelName')"
      v-model="editedName" />

    <div
      class="absolute h-[30px] w-[200px] left-[280px] border-[1px] cursor-pointer border-[#339fff] border-slate-400 rounded-lg"
      @click="this.$emit('playstop')">
      <div class="selected-option">
        <div v-if="!isToggleLoading">
          {{ playingText }}
        </div>
        <div class="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-slate-500 ml-[75px] mt-[2px]" v-else>
        </div>
      </div>
    </div>

    <div class="absolute top-[5px] left-[560px] w-[250px] h-[10px] text-rose-500 cursor-pointer"
      v-if="!propsSourceLanguage">
      <v-autocomplete v-model="selectedSourceLanguage" :items="allLanguages" :label="$t('selectSourceLanguage')"
        item-title="combined_name" item-value="code" return-object variant="plain">
      </v-autocomplete>
    </div>
    <div class="absolute top-[26px] left-[560px] text-blue-800 text-blue-800 cursor-pointer" v-else
      @click="selectedSourceLanguage = null">{{ $t('sourceLanguage') }} {{ displayName }}</div>
    <div class="absolute left-[850px] text-grey">{{ $t('listeners') }} {{ currentListeners }}</div>
    <img class="absolute w-[22px] left-[955px] cursor-pointer" alt="Window close" :src="windowClose"
      @click="$emit('closeClicked')" />

    <v-tooltip v-if="showTooltip" activator="parent" location="top" class="z-20">
      {{ toolTipText }}
    </v-tooltip>
  </div>
</template>

<script>
import windowClose from '@/assets/window-close.svg';
import rotate from '@/assets/rotate.svg';
import down from '@/assets/down.svg';
import up from '@/assets/up.svg';
import { azure_speech_languages_extended } from '../languages_detailed';

export default {
  props: {
    propsEditedName: {
      type: String,
      default: '',
      required: true,
    },
    propsIsChecked: {
      type: Boolean,
      default: false,
      required: true,
    },
    propsIsPlaying: {
      type: Boolean,
      default: false,
      required: true,
    },
    propsSourceLanguage: {
      type: Object,
      default: null,
    },
    propsCurrentListeners: {
      type: Number,
      default: 0,
    },
    isToggleLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      windowClose: windowClose,
      down: down,
      up: up,
      rotate: rotate,
      isDropdownOpen: false,
      editedName: this.propsEditedName,
      isPlaying: this.propsIsPlaying,
      selectedSourceLanguage: null,
      allLanguages: [],
      isChecked: this.propsIsChecked,
      currentListeners: this.propsCurrentListeners,
    };
  },
  created() {
    this.isChecked = this.missingInformations ? false : this.propsIsChecked
    this.updateLanguageList();
  },
  watch: {
    propsCurrentListeners(newValue) {
      this.currentListeners = newValue;
    },
    propsEditedName(newValue) {
      this.editedName = newValue;
    },
    propsIsPlaying(newValue) {
      this.isPlaying = newValue;
    },
    propsIsChecked(newValue) {
      this.isChecked = newValue;
    },
    propsSourceLanguage(newValue) {
      this.selectedSourceLanguage = newValue;
    },
    selectedSourceLanguage(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit('sourcelanguageSelected', newVal);
      }
    },
    missingInformations(newValue) {
      if (newValue || !this.$store.state.planActive) {
        this.isChecked = false;
        this.$emit('checkboxChanged', false);
      } else {
        this.isChecked = this.propsIsChecked;
      }
    },
  },
  computed: {
    showCheckboxTooltip() {
      return !this.$store.state.planActive && !this.missingInformations;
    },
    showTooltip() {
      return this.missingInformations;
    },
    toolTipText() {
      if (this.missingInformations) {
        return this.$t('errorMissingInformations');
      }
      return this.$t('tooltip.selectSourceLanguage');
    },
    missingInformations() {
      return !this.editedName || !this.selectedSourceLanguage;
    },
    playingText() {
      return this.isPlaying ? this.$t('stopInput') : this.$t('playInput');
    },
    borderColor() {
      return this.missingInformations ? 'border-red-500' : 'border-slate-400';
    },
    displayName() {
      return this.selectedSourceLanguage ? this.selectedSourceLanguage.name : this.$t('selectSourceLanguage');
    },
    allowCheckbox() {
      return !this.missingInformations && this.$store.state.planActive;
    },
  },
  methods: {
    updateLanguageList() {
      const languages = azure_speech_languages_extended;
      this.allLanguages = languages.map(lang => ({
        code: lang.code,
        name: lang.name,
        real_name: lang.real_name,
        combined_name: `${lang.name} (${lang.real_name})`,
      }));
    },
    handleInput(event) {
      const inputValue = event.target.value;
      if (inputValue.length <= 12) {
        this.editedName = inputValue;
        this.$emit('nameChanged', inputValue)
      } else {
        this.editedName = inputValue.slice(0, 13);
      }
    },
    toggleIcon() {
      if (this.isDropdownOpen) {
        return up;
      }
      return down;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    selectOption(option) {
      this.$emit('optionSelected', option);
    },
  },
};
</script>

<style>
.original-language-button-component .rectangle {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #c2c2c2;
  border-radius: 4px;
  height: 22px;
  left: 25px;
  position: absolute;
  top: 25px;
  width: 22px;
}

.original-language-button-component .text-wrapper {
  color: #000000;
  font-family: "Inter-Medium", Helvetica;
  font-size: 25px;
  font-weight: 500;
  left: 82px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 20px;
  white-space: nowrap;
}

.original-language-button-component .selected-option {
  color: #000000;
  font-family: "Inter-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  left: 12px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 4px;
}

.original-language-button-component .down {
  height: 24px;
  left: 167px;
  position: absolute;
  top: 2px;
  width: 24px;
}

.original-language-button-component .window-close {
  height: 24px;
  left: 950px;
  position: absolute;
  top: 24px;
  width: 24px;
  cursor: pointer;
}

.original-language-button-component .text-wrapper {
  color: #000000;
  font-family: "Inter-Medium", Helvetica;
  font-size: 25px;
  font-weight: 500;
  left: 82px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 20px;
  white-space: nowrap;
  border: none;
  background-color: transparent;
  outline: none;
}

.original-language-button-component .checkbox-container {
  height: 32px;
  /* Angepasst für äußeren Container */
  left: 25px;
  position: absolute;
  top: 20px;
  width: 32px;
  /* Angepasst für äußeren Container */
  display: flex;
  justify-content: center;
  align-items: center;
}

.original-language-button-component .checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 22px;
  /* Angepasst für Checkbox */
  height: 22px;
  /* Angepasst für Checkbox */
  border: 1px solid #c2c2c2;
  border-radius: 4px;
  /* Leicht angepasst für Konsistenz */
  outline: none;
  cursor: pointer;
}

.original-language-button-component .checkbox:checked {
  background-color: #1f00e4;
  border-color: #1f00e4;
}

.original-language-button-component .checkbox:checked::after {
  content: '';
  display: block;
  width: 8px;
  /* Angepasst für Größe des Hakens */
  height: 14px;
  /* Angepasst für Größe des Hakens */
  border: solid #ffffff;
  border-width: 0 3px 3px 0;
  /* Angepasst für Dicke des Hakens */
  transform: rotate(45deg);
  margin-left: 6px;
  /* Angepasst für Positionierung des Hakens */
  margin-top: 2px;
  /* Angepasst für Positionierung des Hakens */
}
</style>