<template>
    <transition name="fade">
        <div v-if="show" class="z-20 fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
            @click="$emit('close')">
            <div class="relative top-20 mx-auto p-8 border w-96 max-w-[90%] shadow-lg rounded-lg bg-white" @click.stop>
                <!-- Close Button -->
                <button @click="$emit('close')" class="absolute right-4 top-4 text-gray-500 hover:text-gray-700">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>

                <!-- Success Message -->
                <div v-if="submitSuccess" class="text-center py-8">
                    <div class="mx-auto flex items-center justify-center w-12 h-12 rounded-full bg-green-100 mb-4">
                        <svg class="w-6 h-6 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                        </svg>
                    </div>
                    <h3 class="text-lg font-medium text-gray-900 mb-2">{{ $t('contact.successTitle') }}</h3>
                    <p class="text-gray-500">{{ $t('contact.successMessage') }}</p>
                </div>

                <!-- Contact Form -->
                <form v-else @submit.prevent="handleSubmit" class="space-y-4">
                    <h2 class="text-2xl font-bold text-gray-900 mb-6">{{ $t('contact.title') }}</h2>

                    <!-- Name Input -->
                    <div>
                        <label class="block text-sm font-medium text-gray-700 mb-1">
                            {{ $t('contact.name') }}
                        </label>
                        <input type="text" required v-model="formData.name"
                            class="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none" />
                    </div>

                    <!-- Email Input -->
                    <div>
                        <label class="block text-sm font-medium text-gray-700 mb-1">
                            {{ $t('contact.email') }}
                        </label>
                        <input type="email" required v-model="formData.email"
                            class="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none" />
                    </div>

                    <!-- Phone Input -->
                    <div>
                        <label class="block text-sm font-medium text-gray-700 mb-1">
                            {{ $t('contact.phone') }}
                        </label>
                        <input type="tel" v-model="formData.phone"
                            class="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none" />
                    </div>

                    <!-- Congregation Name -->
                    <div>
                        <label class="block text-sm font-medium text-gray-700 mb-1">
                            {{ $t('contact.congregation') }}
                        </label>
                        <input type="text" required v-model="formData.congregation"
                            class="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none" />
                    </div>

                    <!-- Congregation Size -->
                    <div>
                        <label class="block text-sm font-medium text-gray-700 mb-1">
                            {{ $t('contact.size') }}
                        </label>
                        <select v-model="formData.size" required
                            class="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none">
                            <option value="">{{ $t('contact.selectSize') }}</option>
                            <option value="0-50">0-50 {{ $t('contact.members') }}</option>
                            <option value="51-100">51-100 {{ $t('contact.members') }}</option>
                            <option value="101-250">101-250 {{ $t('contact.members') }}</option>
                            <option value="251-500">251-500 {{ $t('contact.members') }}</option>
                            <option value="500+">500+ {{ $t('contact.members') }}</option>
                        </select>
                    </div>

                    <!-- Message -->
                    <div>
                        <label class="block text-sm font-medium text-gray-700 mb-1">
                            {{ $t('contact.message') }}
                        </label>
                        <textarea required v-model="formData.message" rows="4"
                            class="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none"></textarea>
                    </div>

                    <!-- Submit Button -->
                    <button type="submit" :disabled="isSubmitting"
                        class="w-full bg-blue-600 text-white py-3 px-4 rounded-lg hover:bg-blue-700 disabled:bg-blue-300 transition-colors duration-200">
                        {{ isSubmitting ? $t('contact.sending') : $t('contact.send') }}
                    </button>
                </form>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'ContactModal',
    props: {
        show: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            formData: {
                name: '',
                email: '',
                phone: '',
                congregation: '',
                size: '',
                message: ''
            },
            isSubmitting: false,
            submitSuccess: false
        }
    },
    methods: {
        async handleSubmit() {
            this.isSubmitting = true;
            try {
                await this.$store.dispatch('sendPublicContact', {
                    name: this.formData.name,
                    email: this.formData.email,
                    subject: `Neue Kontaktanfrage von ${this.formData.congregation}`,
                    message: `
Name: ${this.formData.name}
E-Mail: ${this.formData.email}
Telefon: ${this.formData.phone}
Gemeinde: ${this.formData.congregation}
Gemeindegröße: ${this.formData.size}

Nachricht:
${this.formData.message}
                `.trim()
                });

                this.submitSuccess = true;
                setTimeout(() => {
                    this.$emit('close');
                    this.resetForm();
                }, 2000);
            } catch (error) {
                // Error wird bereits im Store behandelt
                this.submitSuccess = false;
            } finally {
                this.isSubmitting = false;
            }
        },
        resetForm() {
            this.formData = {
                name: '',
                email: '',
                phone: '',
                congregation: '',
                size: '',
                message: ''
            }
            this.submitSuccess = false
        }
    }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>